<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('pages.all-menus') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <b-button variant="outline-primary" :to="{ name: 'AddMenu' }" class="mb-5">{{ $t("pages.add-menu") }}</b-button>
            <b-list-group>
              <b-list-group-item v-for="m in menus" :key="m.id" class="d-flex align-items-center" :to="{ name : 'EditMenu', params: { languageShortName: languageShortName, menuId: m.id }}">
                <b-avatar variant="primary" icon="BIconMenuApp" class="mr-4"><BIconMenuApp scale="1.5"/> </b-avatar>
                <h3>{{ m.name }}</h3>
              </b-list-group-item>
            </b-list-group>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BAvatar, BIconMenuApp } from 'bootstrap-vue'
export default {
  name: 'MenuForLanguage',
  components: { NavBar, BAvatar, BIconMenuApp},
  data: function() {
    return {
      menus: {},
      languageShortName: this.$route.params.languageShortName,
      showOverlay: true
    }
  },
  created: async function() {
    await this.$http.get("/cms/api/page/menu/all/" + this.languageShortName, { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.menus = r.data;
      }).catch(e => { this.handleError(e); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
